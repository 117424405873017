import { Card, Form, Row, Col, Flex, Spin, Checkbox, Select, Upload, Image, Button, Modal, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import NOTIFICATION_DETAILS, { BASE_URL } from "../utils/constants/Constants";
import sendPost from "../utils/apis/sendPost";
import showNotification from "../utils/views/showNotification";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import Cookies from "universal-cookie";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TemplateOne = ({setInfoSubmit, filename, byDisabled1, setByDisabled1, addDisabled1, setAddDisabled1, setUpload, setTemplate}) => {
    const [spinning, setSpinning] = useState(false);
    const [isRef, setIsRef] = useState(true);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [form] = useForm();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(true);
    const [previewImageData, setPreviewImageData] = useState(null);

    useEffect(() => {
        const cookies = new Cookies(null, { path: '/' });
        const formCookie = cookies.get('template1');

        if (formCookie !== undefined) {
            form.setFieldsValue(formCookie);
            setByDisabled1(form.getFieldValue('byCSV'));
            setAddDisabled1(form.getFieldValue('byAddCsv'));
            setIsRef(form.getFieldValue('isReference'));
        }
    }, [setByDisabled1, setAddDisabled1, setIsRef, form]);

    const save = (e) => {
        e.preventDefault();
        const cookies = new Cookies(null, { path: '/' });
        cookies.set('template1', form.getFieldsValue(true));
    }

    const clear = (e) => {
        e.preventDefault();
        const cookies = new Cookies(null, { path: '/' });
        cookies.remove('template1');
        window.location.reload();
    }

    const showPreview = () => {
        setIsPreviewOpen(true);
    };
    const handleOk = () => {
        setIsPreviewOpen(false);
    };
    const handleCancel = () => {
        setIsPreviewOpen(false);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
        style={{
            border: 0,
            background: 'none',
        }}
        type="button"
        >
        <PlusOutlined />
        <div
            style={{
            marginTop: 8,
            }}
        >
            Upload
        </div>
        </button>
    );

    const handleSubmission = useCallback((result) => {
        if (result.error) {
            showNotification("error", NOTIFICATION_DETAILS.error);
        } else {
            setSpinning(true);
            setInfoSubmit(true);

            if (byDisabled1 || addDisabled1) {
                showNotification("success", NOTIFICATION_DETAILS.success);
            } else {
                setUpload(true);
            }
        }
    }, [setInfoSubmit, setUpload, addDisabled1, byDisabled1]);

    const onFinish = useCallback(async (values) => {
        const result = await sendPost({url: `/submit1/${filename}`, data: JSON.stringify(values)});
        handleSubmission(result);
    }, [filename, handleSubmission]);

    const handleSelection = (value) => {
        setIsRef(value);
    }

    const handleOnePagePreview = () => {
        setLoadingPreview(true);

        form.validateFields()
            .then((values) => {
                showPreview();

                sendPost({url: `/preview1/${filename}`, data: JSON.stringify(values)}).then((response) => {
                    setLoadingPreview(false);
                    setPreviewImageData(response.response.img);
                });
            })
            .catch((error) => {

            });
    }

    const logoUploadUrl = `${BASE_URL}/logo/${filename}`;
      
    return (
        <>
            <Form
            form={form}
            name="info1"
            initialValues={{
                // toIban: 'CH64 3196 1000 0044 2155 7',
            //     toFirstname: 'Max Muster & Söhne',
            //     toStreet: 'Musterstrasse',
            //     toHouseNumber: '123',
            //     toPostcode: '8000',
            //     toCity: 'Seldwyla',
            //     toCountry: 'CH',
                byCSV: false,
            //     byFirstname: 'Simon',
            //     byLastname: 'Muster',
            //     byStreet: 'Musterstrasse',
            //     byHouseNumber: '1',
            //     byPostcode: '8000',
            //     byCity: 'Seldwyla',
            //     byCountry: 'CH',
            //     currency: 'CHF',
            //     amount: '50',
            //     reference: '00 00082 07791 22585 74212 86694',
            //     additional: 'Payment of travel'
                text1: '',
                text2: '',
                text3: '',
                mwst: 0,
                isReference: true,
                byAddCsv: false,
                amount_min: '400',
                amount_max: '900',
            }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Flex justify="end">
                    <Button type="primary" style={{margin: '15px', backgroundColor: 'green'}} onClick={handleOnePagePreview}>Preview</Button>
                </Flex>
                <Row justify='space-between'>
                    <Col span={8}>
                        <Card title="Konto / Zahlbar an" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Form.Item
                                name="toIban"
                                rules={[
                                {
                                    required: true,
                                    message: 'IBAN ist Pflicht'
                                }
                                ]}
                            >
                                <Input placeholder="IBAN / QR IBAN CHF-Konto" />
                            </Form.Item>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="toFirstname"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Vorname / Firma ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Vorname / Firma" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="toLastname"
                                >
                                    <Input placeholder="Nachname" />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toStreet"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Straße ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Straße" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toHouseNumber"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Hausnr ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Hausnr" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toPostcode"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'PLZ ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="PLZ" />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="toCity"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Ort ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Ort" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                {/* <Form.Item
                                    name="toCountry"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Land ist Pflicht'
                                    }
                                    ]}
                                >
                                    <div className="form-control">
                                    <input className="input input-alt" placeholder="Land" type="text" />
                                    <span className="input-border input-border-alt"></span>
                                    </div>
                                </Form.Item> */}
                                </Col>
                            </Row>
                        </Card>
                        <Card title="Zahlbar durch" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Form.Item
                                name="byCSV"
                                valuePropName="checked"
                            >
                                <Checkbox onChange={(e) => setByDisabled1(e.target.checked)} checked={byDisabled1}>Use CSV?</Checkbox>
                            </Form.Item>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="byFirstname"
                                    rules={[
                                    {
                                        required: !byDisabled1,
                                        message: 'Vorname / Firma ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Vorname / Firma" disabled={byDisabled1} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="byLastname"
                                >
                                    <Input placeholder="Nachname" disabled={byDisabled1} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byStreet"
                                    rules={[
                                    {
                                        required: !byDisabled1,
                                        message: 'Straße ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Straße" disabled={byDisabled1} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byHouseNumber"
                                    rules={[
                                    {
                                        required: !byDisabled1,
                                        message: 'Hausnr ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Hausnr" disabled={byDisabled1} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byPostcode"
                                    rules={[
                                    {
                                        required: !byDisabled1,
                                        message: 'PLZ ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="PLZ" disabled={byDisabled1} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="byCity"
                                        rules={[
                                        {
                                            required: !byDisabled1,
                                            message: 'Ort ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Ort" disabled={byDisabled1} />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} md={11}>
                                <Form.Item
                                    name="byCountry"
                                    rules={[
                                    {
                                        required: !byDisabled,
                                        message: 'Land ist Pflicht'
                                    }
                                    ]}
                                >
                                    <div className="form-control">
                                    <input className="input input-alt" placeholder="Land" type="text" disabled={byDisabled} />
                                    <span className="input-border input-border-alt"></span>
                                    </div>
                                </Form.Item>
                                </Col> */}
                            </Row>
                        </Card>
                        <Card title="Zahlungsinformationen" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Row justify='space-between'>
                                {/* <Col xs={24} md={11}>
                                    <Form.Item
                                        name="currency"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Währung ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <div className="form-control">
                                        <input className="input input-alt" placeholder="Währung" type="text" />
                                        <span className="input-border input-border-alt"></span>
                                        </div>
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="amount_min"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Betrag ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Betrag(Min)" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="amount_max"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Betrag ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Betrag(Max)" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                name="isReference"
                            >
                                <Select
                                    onChange={handleSelection}
                                    options={
                                        [
                                            {value: true, label: <span>QRR - QR Referenz</span>},
                                            {value: false, label: <span>NON - Ohne Referenz</span>}
                                        ]
                                    }
                                ></Select>
                            </Form.Item>
                            {
                                isRef ? 
                                <Form.Item
                                    name="reference"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Referenz ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Referenz" />
                                </Form.Item>
                                :
                                <>
                                    <Form.Item
                                        name="byAddCsv"
                                        valuePropName="checked"
                                    >
                                        <Checkbox onChange={(e) => setAddDisabled1(e.target.checked)} checked={addDisabled1}>Use CSV?</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="additional"
                                        rules={[
                                        {
                                            required: !addDisabled1,
                                            message: 'Zusätzliche Informationen ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Zusätzliche Informationen" disabled={addDisabled1} />
                                    </Form.Item>
                                </>
                            }
                        </Card>
                    </Col>
                    <Col span={16}>
                        <div style={{overflow: 'auto'}}>
                            <Card style={{ width: '210mm', borderRadius: '3px', margin: '15px' }} bordered={false}>
                                <Flex justify="end">
                                    <div>
                                        <Upload
                                            accept="image/*"
                                            action={logoUploadUrl}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            >
                                            {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                        {previewImage && (
                                            <Image
                                                wrapperStyle={{
                                                display: 'none',
                                                }}
                                                preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                }}
                                                src={previewImage}
                                            />
                                        )}
                                    </div>
                                </Flex>
                                <Row justify={"space-between"}>
                                    <Col span={11}>
                                        <div style={{height: '150px', backgroundColor: 'gray', marginBottom: '10px'}}></div>
                                    </Col>
                                    <Col span={11}>
                                        <FormItem
                                            name="text1"
                                            style={{
                                                marginBottom: 15,
                                            }}
                                        >
                                            <TextArea rows={3} placeholder="Please input text" />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <FormItem
                                    name="text2"
                                    style={{
                                        marginBottom: 15
                                    }}
                                >
                                    <TextArea rows={3} placeholder="Please input text" />
                                </FormItem>
                                <div style={{height: '150px', backgroundColor: 'gray', marginTop: '10px', marginBottom: '10px'}}></div>
                                <FormItem
                                    name="text3"
                                    
                                >
                                    <TextArea rows={3} placeholder="Please input text" />
                                </FormItem>
                                <Flex justify="end">
                                    <Form.Item
                                        name="mwst"
                                        style={{
                                            width: '100px'
                                        }}
                                    >
                                        <Input placeholder="MwSt" />
                                    </Form.Item>
                                </Flex>
                                <div style={{height: '150px', backgroundColor: 'gray', marginTop: '10px'}}></div>
                            </Card>
                            <Form.Item>
                                <Flex justify="flex-end">
                                    <button className="ui-btn" onClick={save} style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Save</span></button>
                                    <button className="ui-btn" onClick={clear} style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Clear</span></button>
                                    <button className="ui-btn" type="submit" style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Einreichen</span></button>
                                </Flex>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Spin spinning={spinning} fullscreen></Spin>
            </Form>
            <Modal title="Preview" width={1000} open={isPreviewOpen} onOk={handleOk} onCancel={handleCancel}>
                <Flex justify="center" style={{backgroundColor: 'lightgray', padding: '10px'}}>
                    <Spin spinning={loadingPreview} />
                    {
                        !loadingPreview && <img src={previewImageData} alt="bill" />
                    }
                </Flex>
            </Modal>
        </>
    );
}

export default TemplateOne;
