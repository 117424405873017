import { InboxOutlined } from '@ant-design/icons';
import { Row, Col, message, Upload } from 'antd';
import { BASE_URL } from '../utils/constants/Constants';

const { Dragger } = Upload;

const FileUpload = ({ setUpload, filename }) => {
  const url = `${BASE_URL}/upload/${filename}`;

  return (
    <Row justify='center'>
      <Col xs={24} md={12} style={{textAlign: 'center'}}>
        <div style={{padding: 5}}>
          <h1>Excel file Upload</h1>
          <Dragger
            name='file'
            accept='.xlsx'
            action={url}
            onChange={
              (info) => {
                const { status } = info.file;
                if (status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully.`);
                  setUpload(true);
                } else if (status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }
            }
            onDrop={
              (e) => {
                console.log('Dropped files', e.dataTransfer.files);
              }
            }
          >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag excel file to this area to upload</p>
            <p className="ant-upload-hint">
                Support for a single upload.
            </p>
          </Dragger>
        </div>
      </Col>
    </Row>
  );
}

export default FileUpload;
