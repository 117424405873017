import { Col, Flex, Progress, Row, message } from "antd";
import { useEffect, useState } from "react";
import sendPost from "../utils/apis/sendPost";
import { BASE_URL } from "../utils/constants/Constants";

const BillGenerator = ({ filename, template }) => {
    const [generate, setGenerate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [download, setDownload] = useState(false);
    const [isError, setIsError] = useState(false);
    const [progressStatus, setProgressStatus] = useState('active');
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const interval = setInterval(() => {
            getProgress();
        }, 500);
        if (progress === 100) clearInterval(interval);
        if (isError === true) clearInterval(interval);
        return () => clearInterval(interval);
    });

    const getProgress = async () => {
        if (generate === true) {
            const response = await sendPost({ url: '/progress', data: {'filename': filename}});
            const result = response.response.progress;
            const error = response.response.error;

            setProgress(result);
            if (error !== null) {
                setIsError(true);
                setProgressStatus('exception');
                messageApi.open({
                type: 'error',
                content: error,
                duration: 0
                });
            }

            if (result === 100) {
                setDownload(true);
                setProgressStatus('success');
            }
        }
    }

    const onClickGenerate = async (e) => {
        setGenerate(true);
        setDisabled(true);

        sendPost({url: '/generate', data: {'filename': filename}});
    }

    const onClickDownload = (e) => {
        fetch(`${BASE_URL}/download?filename=${filename}`, {
            method: 'GET',
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/pdf'},
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
            new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
            'download',
            `bill.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    return (
        <>
            {contextHolder}
            <Row justify='center'>
                <Col span={24}>
                    {generate && <Progress percent={progress} status={progressStatus} strokeLinecap="butt" style={{ marginBottom: '30px' }} />}
                    <Flex justify="center">
                        {
                            download ? 
                            <button className="ui-btn" onClick={onClickDownload}><span>Download</span></button> :
                            <button className="ui-btn" onClick={onClickGenerate} disabled={disabled}><span>Generate Bills</span></button>}
                    </Flex>
                </Col>
            </Row>
        </>
    );
}

export default BillGenerator;
