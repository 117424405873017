const NOTIFICATION_DETAILS = {
    success: {
      message: "Details Submitted!",
      description:
        "You submittion was succeed! Please upload excel file now.",
    },
    error: {
      message: "Something went wrong!",
      description: "Please try again later!",
    },
  }

// export const BASE_URL = 'http://127.0.0.1:5000/api';
export const BASE_URL = 'https://api.kmuch.cc/api';

export default NOTIFICATION_DETAILS;
