import { Card, Upload, Image, Flex, Row, Col, Button, Spin, Form, Checkbox, Select, Modal, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import TextInput from "./TextInput";
import showNotification from "../utils/views/showNotification";
import NOTIFICATION_DETAILS, { BASE_URL } from "../utils/constants/Constants";
import sendPost from "../utils/apis/sendPost";
import { useForm } from "antd/es/form/Form";
import Cookies from "universal-cookie";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TemplateTwo = ({setInfoSubmit, setByDisabled2, setTemplate, addDisabled2, setAddDisabled2, setUpload, filename, byDisabled2}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [isRef, setIsRef] = useState(true);
    const [form] = useForm();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(true);
    const [previewImageData, setPreviewImageData] = useState(null);

    useEffect(() => {
        const cookies = new Cookies(null, { path: '/' });
        const formCookie = cookies.get('template2');

        if (formCookie !== undefined) {
            form.setFieldsValue(formCookie);
            setByDisabled2(form.getFieldValue('byCSV'));
            setAddDisabled2(form.getFieldValue('byAddCsv'));
            setIsRef(form.getFieldValue('isReference'));
        }
    }, [setByDisabled2, setAddDisabled2, setIsRef, form]);

    const save = (e) => {
        e.preventDefault();
        const cookies = new Cookies(null, { path: '/' });
        cookies.set('template2', form.getFieldsValue(true));
    }

    const clear = (e) => {
        e.preventDefault();
        const cookies = new Cookies(null, { path: '/' });
        cookies.remove('template2');
        window.location.reload();
    }

    const showPreview = () => {
        setIsPreviewOpen(true);
    };
    const handleOk = () => {
        setIsPreviewOpen(false);
    };
    const handleCancel = () => {
        setIsPreviewOpen(false);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
        style={{
            border: 0,
            background: 'none',
        }}
        type="button"
        >
        <PlusOutlined />
        <div
            style={{
            marginTop: 8,
            }}
        >
            Upload
        </div>
        </button>
    );

    const handleSelection = (value) => {
        setIsRef(value);
    }

    const logoUploadUrl = `${BASE_URL}/logo/${filename}`;

    const handleSubmission = useCallback((result) => {
        if (result.error) {
            showNotification("error", NOTIFICATION_DETAILS.error);
        } else {
            setSpinning(true);
            setInfoSubmit(true);

            if (byDisabled2 || addDisabled2) {
                showNotification("success", NOTIFICATION_DETAILS.success);
            } else {
                setUpload(true);
            }
        }
    }, [setInfoSubmit, setUpload, addDisabled2, byDisabled2]);

    const onFinish = useCallback(async (values) => {
        const result = await sendPost({url: `/submit2/${filename}`, data: JSON.stringify(values)});
        handleSubmission(result);
    }, [filename, handleSubmission]);

    const handleOnePagePreview = () => {
        setLoadingPreview(true);

        form.validateFields()
            .then((values) => {
                showPreview();

                sendPost({url: `/preview2/${filename}`, data: JSON.stringify(values)}).then((response) => {
                    setLoadingPreview(false);
                    setPreviewImageData(response.response.img);
                });
            })
            .catch((error) => {

            });
    }

    return (
        <Form
            form={form}
            name="info2"
            onFinish={onFinish}
            initialValues={{
                fields: [
                    {currency: 'CHF', amount: 'Betrag', title: 'Pos.', content: 'Bezeichnung'},
                    {currency: 'CHF', amount: '0', title: '', content: ''}
                ],
                text1: '',
                text2: '',
                text3: '',
                byCSV: false,
                isReference: true,
                byAddCsv: false,
                amount_min: '400',
                amount_max: '900',
            }}
        >
            <Flex justify="end">
                <Button type="primary" style={{margin: '15px', backgroundColor: 'green'}} onClick={handleOnePagePreview}>Preview</Button>
            </Flex>
            <Row>
                <Col span={8}>
                    <Flex vertical>
                        <Card title="Konto / Zahlbar an" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Form.Item
                                name="toIban"
                                rules={[
                                {
                                    required: true,
                                    message: 'IBAN ist Pflicht'
                                }
                                ]}
                            >
                                <Input placeholder="IBAN / QR IBAN CHF-Konto" />
                            </Form.Item>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="toFirstname"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Vorname / Firma ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Vorname / Firma" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="toLastname"
                                >
                                    <Input placeholder="Nachname" />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toStreet"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Straße ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Straße" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toHouseNumber"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Hausnr ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Hausnr" />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="toPostcode"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'PLZ ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="PLZ" />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="toCity"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Ort ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Ort" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                {/* <Form.Item
                                    name="toCountry"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Land ist Pflicht'
                                    }
                                    ]}
                                >
                                    <div className="form-control">
                                    <input className="input input-alt" placeholder="Land" type="text" />
                                    <span className="input-border input-border-alt"></span>
                                    </div>
                                </Form.Item> */}
                                </Col>
                            </Row>
                        </Card>
                        <Card title="Zahlbar durch" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Form.Item
                                name="byCSV"
                                valuePropName="checked"
                            >
                                <Checkbox onChange={(e) => setByDisabled2(e.target.checked)} checked={byDisabled2}>Use CSV?</Checkbox>
                            </Form.Item>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="byFirstname"
                                    rules={[
                                    {
                                        required: !byDisabled2,
                                        message: 'Vorname / Firma ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Vorname / Firma" disabled={byDisabled2} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                <Form.Item
                                    name="byLastname"
                                >
                                    <Input placeholder="Nachname" disabled={byDisabled2} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byStreet"
                                    rules={[
                                    {
                                        required: !byDisabled2,
                                        message: 'Straße ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Straße" disabled={byDisabled2} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byHouseNumber"
                                    rules={[
                                    {
                                        required: !byDisabled2,
                                        message: 'Hausnr ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Hausnr" disabled={byDisabled2} />
                                </Form.Item>
                                </Col>
                                <Col xs={24} md={7}>
                                <Form.Item
                                    name="byPostcode"
                                    rules={[
                                    {
                                        required: !byDisabled2,
                                        message: 'PLZ ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="PLZ" disabled={byDisabled2} />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="byCity"
                                        rules={[
                                        {
                                            required: !byDisabled2,
                                            message: 'Ort ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Ort" disabled={byDisabled2} />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} md={11}>
                                <Form.Item
                                    name="byCountry"
                                    rules={[
                                    {
                                        required: !byDisabled,
                                        message: 'Land ist Pflicht'
                                    }
                                    ]}
                                >
                                    <div className="form-control">
                                    <input className="input input-alt" placeholder="Land" type="text" disabled={byDisabled} />
                                    <span className="input-border input-border-alt"></span>
                                    </div>
                                </Form.Item>
                                </Col> */}
                            </Row>
                        </Card>
                        <Card title="Zahlungsinformationen" style={{ alignItems: 'center', borderRadius: '3px', margin: '15px' }} bordered={false}>
                            <Row justify='space-between'>
                                {/* <Col xs={24} md={11}>
                                    <Form.Item
                                        name="currency"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Währung ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <div className="form-control">
                                        <input className="input input-alt" placeholder="Währung" type="text" />
                                        <span className="input-border input-border-alt"></span>
                                        </div>
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="amount_min"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Betrag ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Betrag(Min)" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={11}>
                                    <Form.Item
                                        name="amount_max"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Betrag ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Betrag(Max)" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                name="isReference"
                            >
                                <Select
                                    onChange={handleSelection}
                                    options={
                                        [
                                            {value: true, label: <span>QRR - QR Referenz</span>},
                                            {value: false, label: <span>NON - Ohne Referenz</span>}
                                        ]
                                    }
                                ></Select>
                            </Form.Item>
                            {
                                isRef ? 
                                <Form.Item
                                    name="reference"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Referenz ist Pflicht'
                                    }
                                    ]}
                                >
                                    <Input placeholder="Referenz" />
                                </Form.Item>
                                :
                                <>
                                    <Form.Item
                                        name="byAddCsv"
                                        valuePropName="checked"
                                    >
                                        <Checkbox onChange={(e) => setAddDisabled2(e.target.checked)} checked={addDisabled2}>Use CSV?</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="additional"
                                        rules={[
                                        {
                                            required: !addDisabled2,
                                            message: 'Zusätzliche Informationen ist Pflicht'
                                        }
                                        ]}
                                    >
                                        <Input placeholder="Zusätzliche Informationen" disabled={addDisabled2} />
                                    </Form.Item>
                                </>
                            }
                        </Card>
                        </Flex>
                    </Col>
                    <Col span={16}>
                    <Flex justify="center" style={{marginTop: '15px'}}>
                    <div style={{overflow: 'auto'}}>
                        <Card style={{width: '210mm', borderRadius: '3px'}} bordered={false}>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <div>
                                        <Upload
                                            action={logoUploadUrl}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                        >
                                            {fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                        {previewImage && (
                                            <Image
                                                wrapperStyle={{
                                                display: 'none',
                                                }}
                                                preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                }}
                                                src={previewImage}
                                            />
                                        )}
                                    </div>
                                    {/* <Space direction="vertical">
                                        <Input placeholder="IBAN / QR IBAN CHF-Konto" />
                                        <Space style={{marginBottom: '10px'}}>
                                            <Input />
                                            <Input />
                                            <Input />
                                        </Space>
                                    </Space> */}
                                    <Form.Item
                                        name="text1"
                                        style={{margin: 0}}
                                    >
                                        <ReactQuill
                                            // value={text1}
                                            // onChange={setText1}
                                            modules={
                                                {
                                                    toolbar: ['bold', 'italic', 'underline', 'link', 'blockquote', 'code-block', {'list': 'ordered'}, {'list': 'bullet'}, {'align': []}, 'clean']
                                                }
                                            }
                                            style={{
                                                height: '50px',
                                                marginBottom: '50px'
                                            }}
                                        />
                                    </Form.Item>
                                    <div style={{height: '100px', backgroundColor: 'gray', marginBottom: '10px'}}>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        name="text2"
                                        style={{margin: 0}}
                                    >
                                        <ReactQuill
                                            // value={text2}
                                            // onChange={setText2}
                                            modules={
                                                {
                                                    toolbar: ['bold', 'italic', 'underline', 'link', 'blockquote', 'code-block', {'list': 'ordered'}, {'list': 'bullet'}, {'align': []}, 'clean']
                                                }
                                            }
                                            style={{
                                                height: '160px',
                                                marginBottom: '50px'
                                            }}
                                        />
                                    </Form.Item>
                                    <div style={{height: '100px', backgroundColor: 'gray', marginBottom: '10px'}}>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Item
                                name="text3"
                                style={{margin: 0}}
                            >
                                <ReactQuill
                                    // value={text3}
                                    // onChange={setText3}
                                    modules={
                                        {
                                            toolbar: ['bold', 'italic', 'underline', 'link', 'blockquote', 'code-block', {'list': 'ordered'}, {'list': 'bullet'}, {'align': []}, 'clean']
                                        }
                                    }
                                    style={{
                                        height: '80px',
                                        marginBottom: '50px'
                                    }}
                                />
                            </Form.Item>
                            <Form.List name="fields">
                                {(fields, { add, remove }) => {
                                    return (
                                        <div>
                                            {fields.map((field, index) => (
                                                <Row justify='space-between' align='bottom' key={field.key}>
                                                    <Col span={21}>
                                                        <TextInput
                                                            index={index}
                                                        />
                                                    </Col>
                                                    <Col span={2}>
                                                        {
                                                            index > 0 &&
                                                            <Form.Item style={{marginBottom: '10px'}}>
                                                                <Button type="primary" onClick={() => {if (fields.length > 1) remove(field.name);}} block danger>
                                                                    <MinusOutlined />
                                                                </Button>
                                                            </Form.Item>
                                                        }
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Form.Item>
                                                <Flex justify="center">
                                                    <Button type="dashed" onClick={() => add({currency: 'CHF', amount: 0, title: '', content: ''})}>
                                                        <PlusOutlined /> Add field
                                                    </Button>
                                                </Flex>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                                }
                            </Form.List>
                            <div style={{height: '150px', backgroundColor: 'gray', marginTop: '10px'}}></div>
                        </Card>
                        <Form.Item>
                            <Flex justify="end" style={{paddingTop: '10px'}}>
                                <button className="ui-btn" onClick={save} style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Save</span></button>
                                <button className="ui-btn" onClick={clear} style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Clear</span></button>
                                <button className="ui-btn" type="submit" style={{ marginTop: '15px', marginLeft: '15px', marginRight: '15px' }}><span>Einreichen</span></button>
                            </Flex>
                        </Form.Item>
                    </div>
                </Flex>
                </Col>
            </Row>
            <Spin spinning={spinning} fullscreen></Spin>
            <Modal title="Preview" width={1000} open={isPreviewOpen} onOk={handleOk} onCancel={handleCancel}>
                <Flex justify="center" style={{backgroundColor: 'lightgray', padding: '10px'}}>
                    <Spin spinning={loadingPreview} />
                    {
                        !loadingPreview && <img src={previewImageData} alt="bill" />
                    }
                </Flex>
            </Modal>
        </Form>
    );
}

export default TemplateTwo;
