import { BASE_URL } from "../constants/Constants";

const sendPost = async ({url, data}) => {
    const submitRequest = async (reqBody) => {
        try {
            const res = await fetch(
                `${BASE_URL}${url}`,
                {
                    method: 'POST',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(reqBody)
                }
            )
            
            const json = await res.json();
            return { response: json, error: undefined };
        } catch (error) {
            return { response: undefined, error: error };
        }
    };

    return await submitRequest(data);
}

export default sendPost;
