import { Col, Input, Row, Select, Form } from "antd";
import ReactQuill from "react-quill";

const TextInput = ({index}) => {
    return (
        <Row justify='space-between' align='bottom' style={{marginTop: '10px', marginBottom: '10px'}}>
            <Col span={3}>
                <Form.Item
                    name={[index, 'title']}
                    style={{margin: 0}}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={14}>
                <Form.Item
                    name={[index, 'content']}
                    style={{margin: 0}}
                >
                    <ReactQuill
                        // value={content}
                        // onChange={(value) => setContent(index, value)}
                        modules={
                            {
                                toolbar: ['bold', 'italic', 'underline', 'link', 'blockquote', 'code-block', {'list': 'ordered'}, {'list': 'bullet'}, {'align': []}, 'clean']
                            }
                        }
                        style={{
                            height: '45px',
                            marginBottom: '40px'
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={3}>
                <Form.Item
                    name={[index, 'currency']}
                    style={{margin: 0}}
                >
                    <Select
                        style={{width: '80px'}}
                        // onChange={(value) => setCurrency(index, value)}
                        options={[
                            {
                                value: 'CHF',
                                label: 'CHF'
                            },
                            {
                                value: 'EURO',
                                label: 'EURO'
                            }
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col span={3}>
                <Form.Item
                    name={[index, 'amount']}
                    style={{margin: 0}}
                >
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    );
}

export default TextInput;
