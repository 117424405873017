import React, { useEffect, useState } from "react";
import { Layout, Tabs } from "antd";

import './App.css';
import TemplateOne from "./components/TemplateOne";
import FileUpload from "./components/fileUpload";
import BillGenerator from "./components/BillGenerator";
import TemplateTwo from "./components/TemplateTwo";
import { BASE_URL } from "./utils/constants/Constants";

const { Header, Footer, Content } = Layout;

function App() {
  const [infoSubmit, setInfoSubmit] = useState(false);
  const [filename, setFilename] = useState(null);
  const [upload, setUpload] = useState(false);
  const [byDisabled1, setByDisabled1] = useState(false);
  const [addDisabled1, setAddDisabled1] = useState(false);
  const [byDisabled2, setByDisabled2] = useState(false);
  const [addDisabled2, setAddDisabled2] = useState(false);
  const [template, setTemplate] = useState(1);

  const tabs = [
    {
      key: '1',
      label: 'Template 1',
      children: <TemplateOne filename={filename} addDisabled1={addDisabled1} setAddDisabled1={setAddDisabled1} byDisabled1={byDisabled1} setInfoSubmit={setInfoSubmit} setUpload={setUpload} setByDisabled1={setByDisabled1} setTemplate={setTemplate} />
    },
    {
      key: '2',
      label: 'Template 2',
      children: <TemplateTwo filename={filename} addDisabled2={addDisabled2} setAddDisabled2={setAddDisabled2} byDisabled2={byDisabled2} setInfoSubmit={setInfoSubmit} setUpload={setUpload} setByDisabled2={setByDisabled2} setTemplate={setTemplate} />
    }
  ]

  useEffect(() => {
    fetch(`${BASE_URL}/init`, {
        method: 'GET',
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
    })
    .then((response) => response.json())
    .then((json) => {
      setFilename(json['filename'])
    })
  }, [setFilename])

  const handleTabChange = (value) => {
    setTemplate(value);
  }

  return (
    <Layout>
      <Header style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '30px', color: 'white' }}>
        <a href="/" style={{ color: 'white' }}>QR Billing System</a>
      </Header>
      <Content style={{ padding: '15px' }}>
        {
          infoSubmit ?
          upload ? <BillGenerator filename={filename} template={template} /> :
          template === 1 ?
          ((byDisabled1 || addDisabled1) ? <FileUpload filename={filename} setUpload={setUpload} /> :
                       <BillGenerator filename={filename} template={template} />) :
          ((byDisabled2 || addDisabled2) ? <FileUpload filename={filename} setUpload={setUpload} /> :
                       <BillGenerator filename={filename} template={template} />) :
                       <Tabs onChange={handleTabChange} defaultActiveKey="1" items={tabs} />
          // <TemplateOne setInfoSubmit={setInfoSubmit} setFilename={setFilename} byDisabled={byDisabled} setByDisabled={setByDisabled} setUpload={setUpload} />
        }
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        QR Billing System ©{new Date().getFullYear()} Created by Bit
      </Footer>
    </Layout>
  );
}

export default App;
